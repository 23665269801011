import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://424e33a99cd5ec7c90bb3e11999eb61e@o4507423667453952.ingest.de.sentry.io/4507423672827984",
  debug: false,
  environment: import.meta.env.PUBLIC_VERCEL_ENV,
  release: import.meta.env.PUBLIC_VERCEL_GIT_COMMIT_SHA,
  tracesSampleRate: 1,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,
});